import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../shared/modules/material/material.module';

import { DeleteCourseComponent } from './delete-course.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule
  ],
  declarations: [DeleteCourseComponent],
  exports: [DeleteCourseComponent],

})
export class DeleteCourseModule { }
