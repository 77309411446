import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CourseInfoComponent } from '../course-info/course-info.component';
import { Location } from '@angular/common';
import { CourseService } from '../../services/course.service';
import { Course } from '../../obj/courseObj';
import { Router } from '@angular/router';
@Component({
  selector: 'app-delete-course',
  templateUrl: './delete-course.component.html',
  styleUrls: ['./delete-course.component.scss']
})
export class DeleteCourseComponent implements OnInit {
  courseId='';
  courseForDeletePayload: Course = new Course();
  courseisActive;
  noOfItems;
  checkBoxForDelete: any[] = [];
  tempcheckBoxForDelete:any[]=[];
  fromAdminPanel: Boolean=false;

  constructor(public router: Router, private courseService: CourseService, public snackBar: MatSnackBar, private _location: Location,
    public dialogRef: MatDialogRef<CourseInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  ngOnInit() {
    this.courseId = this.data.c;
    this.courseisActive = this.data.isActive;
    this.fromAdminPanel=this.data.fromAdminPanel;
    this.checkBoxForDelete = this.data.numSelected;
    this.noOfItems = this.checkBoxForDelete;
    if (this.noOfItems == undefined || this.noOfItems == null) {
      this.courseService.getCourseId(this.courseId, this.courseisActive).subscribe((response) => {
        this.courseForDeletePayload = <Course>response.data;
      });
    } else {
      this.courseForDeletePayload = null;
    }
  }


  delete() {
    if (this.courseForDeletePayload != null) {
      this.courseForDeletePayload.isActive = false;
      this.courseService.delete(this.courseForDeletePayload).subscribe((response) => {
        this.snackBar.open(this.courseForDeletePayload.name, 'Deleted', {
          duration: 500,
        });
        this.dialogRef.close();
        //according to admin user it will navigate
          if(this.fromAdminPanel==true){
          this.router.navigate(['/admin/course/browse']);
        }
        else{
          this.router.navigate(['/user/category/browse'])
        }
      });
    }
    else {
      for (let i = 0; i < this.checkBoxForDelete.length; i++) {
        let course = {
          courseId: this.checkBoxForDelete[i].courseId
        }
        this.tempcheckBoxForDelete.push(course);
      }
      this.courseService.bulkDeleteCourse(this.tempcheckBoxForDelete).subscribe(response => {
        this.snackBar.open(this.noOfItems.length  + ' TOPICS', 'Deleted', {
          duration: 750,
        });
        this.dialogRef.close();
        //according to admin user it will navigate
        if(this.fromAdminPanel==true){
          this.router.navigate(['/admin/course/browse']);
        }
        else{
          this.router.navigate(['/user/category/browse'])
        }
      });
    }

  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
